import React, { useState } from 'react';
import { Router } from "@reach/router";
import styles from "../styles/resetpassword.module.css";
import { message } from 'antd';


function ResetWorker({ id }) {
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');

  function handlePasswordOne(e) {
    setPasswordOne(e.target.value);
  }

  function handlePasswordTwo(e) {
    setPasswordTwo(e.target.value);
  }

  // Submit new password
  function handleSubmit(e) {
    e.preventDefault();
    if (passwordOne === passwordTwo) {
        fetch('https://hidden-coast-81604.herokuapp.com/account/resetpassword', {
          method: 'POST',
          headers: { 'Content-Type':'application/x-www-form-urlencoded' },
          body: `userID=${id}&password=${passwordOne.trim()}`
        }).then(function(response) {
          message.success('Reset password successful. Please close this website and return to Meet Star Gazers app.', 6);
        }).catch(function(error) {
          message.error('Password reset unsuccessful', 6);
        });
    } else if (passwordOne !== passwordTwo) {
      console.log('Passwords donot match');
      message.error('Passwords donot match', 6);
    }
  }

  return(
    <div className={styles.resetBackground}>
      <div className={styles.resetForm}>
        <img
          src={require('../images/Meet Star Gazers Only Picto.svg')}
          className={styles.resetLogo}
          alt="Meet Star Gazers"
        />
        <p className={styles.resetTitle}>Reset your password</p>
        <p className={styles.resetInstruction}>Passwords should contain atleast 8 characters.</p>
        <form className={styles.fieldEnveloppe} onSubmit={handleSubmit}>
          <input type="password" required minLength={8} className={styles.passwordOne} placeholder="Type your new password" value={passwordOne} onChange={handlePasswordOne}/>
          <input type="password" required minLength={8} className={styles.passwordTwo} placeholder="Type your new password one more time" value={passwordTwo} onChange={handlePasswordTwo}/>
          <button type="submit" className={styles.resetButton}>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default function ResetPassword() {
  return (
    <div>
      <Router basepath="/">
        <ResetWorker path="/resetpassword/:id" />
      </Router>
    </div>
  )
}
